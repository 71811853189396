<template>
  <PatientInfoLayout>
    <b-container fluid>
      <div
        c
        class="tw-top-0 tw-py-5 lg:tw-flex tw-justify-between tw-items-center tw-flex-none tw-px-4"
      >
        <div class="tw-flex-initial tw-flex tw-items-center tw-text-warning">
          <span
            class="back-arrow tw-mr-3 tw-cursor-pointer"
            @click="$router.back()"
          ></span>
          <span v-if="previousRoute" class="tw-text-warning">
            {{ previousRoute.meta.title }}
          </span>
        </div>
        <div class="lg:tw-pr-6">
          <b-button
            size="sm"
            class="tw-text-primary tw-underline"
            variant="link"
            pill
            @click="$router.push(`/patients/${patientId}/intake`)"
          >
            View Intake History
          </b-button>
        </div>
      </div>
      <b-row
        align-h="center"
        align-v="center"
        style="padding-top: 20px; padding-bottom: 20px"
        class="tw-px-4"
      >
        <b-col cols="12">
          <b-row>
            <b-col v-if="patientInfo && patientInfo.firstname" cols="12">
              <div
                v-if="unreadMessageCounter > 0"
                class="tw-bg-[#FF8D40] tw-text-[#FFECCA] tw-p-1 tw-my-4 tw-text-center tw-cursor-pointer"
                @click="() => onMessageRedirect(false)"
              >
                You have {{ unreadMessageCounter }}
                {{ pluralize(unreadMessageCounter, "message") }} from
                {{ `${patientInfo.firstname} ${patientInfo.lastname}` }}
              </div>
              <div
                v-if="unreadAdminMessage > 0"
                class="tw-bg-[#FF8D40] tw-text-[#FFECCA] tw-p-1 tw-my-4 tw-text-center tw-cursor-pointer"
                @click="() => onMessageRedirect(true)"
              >
                You have {{ unreadAdminMessage }}
                {{ pluralize(unreadAdminMessage, "message") }} from Admin
              </div>
            </b-col>
            <b-col cols="12">
              <h4 class="mr-4 text-primary">Patient Information</h4>
              <b-card
                v-if="!patientInfo.patientno"
                class="prescription-card"
                no-body
              >
                <b-row align-v="center">
                  <b-col cols="12" md="9">
                    <b-row align-v="center">
                      <b-col cols="auto" class="mb-3">
                        <b-skeleton
                          animation="fade"
                          type="avatar"
                          size="72px"
                        ></b-skeleton>
                      </b-col>
                      <b-col>
                        <b-skeleton animation="fade" width="45%"></b-skeleton>
                        <b-skeleton animation="fade" width="55%"></b-skeleton>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="12" md="3" class="text-right">
                    <b-skeleton animation="fade" width="85%"></b-skeleton>
                    <b-skeleton animation="fade" width="65%"></b-skeleton>
                  </b-col>
                </b-row>
              </b-card>
              <b-card v-else class="prescription-card" no-body>
                <b-row>
                  <b-col cols="12">
                    <b-row align-v="center">
                      <b-col cols="12" lg="9">
                        <b-row align-v="center">
                          <b-col cols="4" lg="2" class="mb-3">
                            <b-avatar
                              size="72px"
                              :src="patientInfo.profileUrl"
                            ></b-avatar>
                          </b-col>
                          <b-col cols="8" lg="10">
                            <h3 class="mb-1 bold">
                              {{
                                `${patientInfo.firstname} ${patientInfo.lastname}`
                              }}
                              <small
                                v-if="patientInfo.transferredfromdoctorname"
                              >
                                (Transferred from Dr.
                                {{ patientInfo.transferredfromdoctorname }})
                              </small>
                            </h3>
                            <template v-if="patientInfo.desiredplan">
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Requested Medication:
                                </b-col>
                                <b-col class="text-black">
                                  <span
                                    v-for="(plan, i) in patientInfo.desiredplan"
                                    :key="i"
                                  >
                                    {{
                                      plan.name +
                                      (plan.pricingdetail != undefined
                                        ? " - " + plan.pricingdetail.nickname
                                        : (i ===
                                          patientInfo.desiredplan.length - 1
                                            ? ""
                                            : ",") || "")
                                    }}

                                    <br
                                      v-if="plan.pricingdetail != undefined"
                                    />
                                  </span>
                                </b-col>
                                <!-- <b-col class="text-black">
                                <span  v-for="(plan, i) in patientInfo.desiredplan" :key="i">
                                  {{ plan.name +  (i === patientInfo.desiredplan.length -1 ? "": ',') || '' }}
                                </span>
                              </b-col> -->
                              </b-row>
                            </template>
                            <template
                              v-else-if="patientInfo.preferredtreatment"
                            >
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Requested Medication:
                                </b-col>
                                <b-col class="text-black">
                                  {{ patientInfo.preferredtreatment || "" }}
                                </b-col>
                              </b-row>
                            </template>

                            <template v-else>
                              <b-row class="py-1">
                                <b-col cols="12" lg="4">
                                  Requested Medication:
                                </b-col>
                                <b-col class="text-black">
                                  Let doctor choose medication
                                </b-col>
                              </b-row>
                            </template>

                            <template v-if="patientInfo.upsell.length > 0">
                              <b-row class="py-1">
                                <b-col cols="12" lg="4"
                                  >Requested Upsell:</b-col
                                >
                                <b-col class="text-black">
                                  <div
                                    v-for="(upsell, i) in patientInfo.upsell"
                                    :key="i"
                                  >
                                    {{ upsell.name }}
                                    <span v-if="upsell.subscriptiontype">
                                      ({{ upsell.subscriptiontype }})
                                    </span>
                                  </div>
                                </b-col>
                              </b-row>
                            </template>

                            <template v-if="patientInfo.preferredshipmentname">
                              <b-row class="py-1">
                                <b-col cols="12" lg="4"
                                  >Preferred Shipment:</b-col
                                >
                                <b-col class="text-black">
                                  <span>
                                    {{
                                      patientInfo.preferredshipmentname || ""
                                    }}
                                  </span>
                                </b-col>
                              </b-row>
                            </template>

                            <template
                              v-if="
                                patientInfo.unselectedproduct != undefined &&
                                patientInfo.unselectedproduct.length > 0
                              "
                            >
                              <b-row class="py-1">
                                <b-col cols="12" lg="4"
                                  >Declined onboarding treatment:</b-col
                                >
                                <b-col class="text-black">
                                  <span
                                    v-for="(
                                      plan, i
                                    ) in patientInfo.unselectedproduct"
                                    :key="i"
                                  >
                                    {{
                                      plan.name +
                                        (i ===
                                        patientInfo.unselectedproduct.length - 1
                                          ? ""
                                          : ",") || ""
                                    }}
                                  </span>
                                </b-col>
                              </b-row>
                            </template>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col
                        cols="12"
                        lg="3"
                        class="text-right tw-mt-10 md:tw-mt-0"
                      >
                        <h3 class="mb-0 bold">
                          {{ patientInfo.patientno }}
                        </h3>
                        <a
                          v-b-modal.intake
                          href="#"
                          class="text-underline"
                          @click="getHealthQuestions"
                        >
                          View Intake History
                        </a>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row v-if="prescriptions.length > 0" align-h="center">
            <b-col cols="12">
              <h4 class="mr-4 text-primary">Follow-up</h4>
              <b-card class="followup-card" style="position: relative">
                <b-row v-if="patientInfo.followupdate">
                  <b-col cols="12" md="3" class="tw-mb-4 lg:tw-mb-0">
                    <span class="mb-1 text-primary">
                      <small>Current Follow up Date</small>
                    </span>
                    <p class="mb-0 text-default">
                      {{
                        parseDate(patientInfo.followupdatestamp, "MMMM d, yyyy")
                      }}
                    </p>
                  </b-col>
                  <b-col cols="12" md="3">
                    <span class="mb-1 text-primary">
                      <small>Months Until Follow Up</small>
                    </span>
                    <p class="mb-0 text-default">
                      {{ monthsUntilFollowUp }}
                      {{ pluralize(monthsUntilFollowUp, "Month") }}
                    </p>
                  </b-col>
                </b-row>
                <b-row v-else class="mb-4">
                  <b-col cols="12" md="6">
                    <div role="group">
                      <label for="followup" class="text-primary">
                        <small>Schedule Follow-up</small>
                      </label>
                      <v-select
                        id="followup"
                        v-model="selectedFollowUpOption"
                        append-to-body
                        :calculate-position="withPopper"
                        label="text"
                        :options="followUpOptions"
                        :reduce="(followUpOptions) => followUpOptions.value"
                      >
                        <template #open-indicator="{ attributes }">
                          <b-icon
                            v-bind="attributes"
                            icon="chevron-down"
                            variant="primary"
                          ></b-icon>
                        </template>
                      </v-select>
                    </div>
                  </b-col>
                  <b-col cols="12" md="6" class="tw-mt-6 md:tw-mt-0">
                    <label for="followupdate" class="text-primary">
                      <small>Follow-up Date</small>
                    </label>
                    <b-form-datepicker
                      id="followupdate"
                      v-model="followupDate"
                      :disabled="selectedFollowUpOption !== 'Manual'"
                      :date-disabled-fn="dateDisabled"
                    ></b-form-datepicker>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>

          <b-row v-if="prescriptions.length > 0" align-h="center" class="pb-6">
            <b-col cols="12">
              <h4 class="mr-4 text-primary">Prescription Order</h4>
              <b-alert :show="alert.show" :variant="alert.type">{{
                alert.message
              }}</b-alert>
              <b-card class="prescription-details" style="position: relative">
                <b-row class="mb-4">
                  <b-col cols="12" md="6">
                    <label for="subscription_startdate" class="text-primary">
                      <small>Subscription Start Date</small>
                    </label>
                    <b-form-datepicker
                      id="subscription_startdate"
                      v-model="subscriptionstartdate"
                      :date-disabled-fn="subscriptionStartDateDisabled"
                    ></b-form-datepicker>
                  </b-col>
                </b-row>
              </b-card>
              <b-card
                v-for="order in orders.prescription"
                :key="order.id"
                class="update-order"
                style="position: relative"
              >
                <b-button
                  style="
                    position: absolute;
                    top: 10px;
                    right: 10px;
                    padding: 2px 0px;
                    cursor: pointer;
                    z-index: 5;
                  "
                  size="sm"
                  variant="link"
                  @click="removePrescriptionOrder(order.id)"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="11.5" stroke="#1A6A72" />
                    <path
                      d="M14.903 14.189L12.7638 12.0499L14.903 9.91077L14.1899 9.19773L12.0508 11.3369L9.91162 9.19773L9.19857 9.91077L11.3377 12.0499L9.19857 14.189L9.91162 14.9021L12.0508 12.763L14.1899 14.9021L14.903 14.189Z"
                      fill="#1A6A72"
                    />
                  </svg>
                </b-button>

                <div
                  class="tw-grid tw-grid-cols-10 tw-mb-4 tw-space-y-4 lg:tw-space-y-0 md:tw-space-x-4 tw-place-items-stretch"
                >
                  <div
                    role="group"
                    class="tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-4"
                  >
                    <label for="prescription" class="text-primary">
                      <small>Prescription</small>
                    </label>
                    <v-select
                      id="prescription"
                      v-model="order.productid"
                      class="prescription-select"
                      append-to-body
                      :calculate-position="withPopper"
                      label="text"
                      :options="prescriptions"
                      :disabled="prescriptions.length <= 0"
                      :reduce="(prescriptions) => prescriptions.value"
                      :searchable="false"
                      @input="changePrescription(order)"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                  <div
                    role="group"
                    class="tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-2"
                  >
                    <label for="dosage" class="text-primary">
                      <small>Dosage</small>
                    </label>
                    <v-select
                      id="dosage"
                      v-model="order.dosage"
                      class="prescription-select"
                      append-to-body
                      :calculate-position="withPopper"
                      label="text"
                      :options="
                        dosages[order.productid] &&
                        dosages[order.productid].filter(
                          ({ value }) => value !== ''
                        )
                      "
                      :reduce="(dosages) => dosages.value"
                      :disabled="!order.productid"
                      @input="changeDosage(order)"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                  <div
                    role="group"
                    class="tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-3"
                  >
                    <label for="unit" class="text-primary">
                      <small>Unit</small>
                    </label>
                    <v-select
                      id="unit"
                      v-model="order.pricingid"
                      class="prescription-select"
                      append-to-body
                      :calculate-position="withPopper"
                      label="text"
                      :options="
                        units[order.productid] &&
                        units[order.productid].filter(
                          (unit) => unit.dosage === order.dosage
                        )
                      "
                      :reduce="(units) => units.value"
                      clearable
                      :disabled="!order.dosage"
                      @input="selectedUnit(order.productid, order.pricingid)"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                  <div
                    role="group"
                    class="tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-1"
                  >
                    <label for="unit" class="text-primary">
                      <small>Refill</small>
                    </label>
                    <b-form-input
                      id="refill"
                      v-model.number="order.refillinput"
                      type="number"
                      min="0"
                      :formatter="refillInputFormatter"
                    ></b-form-input>
                  </div>
                </div>

                <b-row>
                  <!-- <b-col cols="12" md="6">
                  <b-form-group label="Prescription Date">
                    <b-input-group class="mb-2">
                      <flat-pickr
                        :config="config"
                        class="form-control pl-0"
                        v-model="order.startdate"
                        placeholder="MM/DD/YYYY"
                        id="prescription_date"
                      ></flat-pickr>
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6">
                  <div role="group">
                    <label for="frequency">Frequency</label>
                    <v-select
                      id="frequency"
                      append-to-body
                      :calculate-position="withPopper"
                      v-model="order.frequency"
                      :options="frequency"
                    >
                      <template #open-indicator="{ attributes }">
                        <b-icon
                          v-bind="attributes"
                          icon="chevron-down"
                          variant="primary"
                        ></b-icon>
                      </template>
                    </v-select>
                  </div>
                </b-col> -->
                  <b-col cols="12">
                    <div role="group">
                      <label for="textarea" class="text-primary">
                        <small>Doctor Notes</small>
                      </label>
                      <b-form-textarea
                        id="textarea"
                        v-model="order.notes"
                        class="pl-0"
                        rows="3"
                        max-rows="6"
                        style="overflow: auto"
                      ></b-form-textarea>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
              <b-row class="tw-space-y-4 lg:tw-space-y-0">
                <b-col cols="12" md="6">
                  <a class="btn btn-default" @click="addNewPrescription()">
                    + add new order
                  </a>
                </b-col>
                <b-col cols="12" md="3">
                  <b-button pill variant="outline-primary" block @click="cancel"
                    >Cancel</b-button
                  >
                </b-col>
                <b-col cols="12" md="3">
                  <b-button
                    pill
                    variant="primary"
                    block
                    :disabled="processing"
                    @click="() => addPrescription()"
                    >{{ processing ? "Processing" : "Save" }}</b-button
                  >
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <b-card style="background-color: rgba(247, 247, 247, 0.5)">
                <b-row class="mb-4">
                  <b-col cols="12" md="6">
                    <b-skeleton animation="fade" type="input"></b-skeleton>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-skeleton animation="fade" type="input"></b-skeleton>
                  </b-col>
                  <b-col cols="12" md="3">
                    <b-skeleton animation="fade" type="input"></b-skeleton>
                  </b-col>
                </b-row>
                <b-row class="mb-4">
                  <b-col cols="12">
                    <b-skeleton
                      animation="fade"
                      type="input"
                      height="150px"
                    ></b-skeleton>
                  </b-col>
                </b-row>
              </b-card>
              <b-row>
                <b-col cols="4" md="6">
                  <b-skeleton type="button" width="150px"></b-skeleton>
                </b-col>
                <b-col cols="4" md="3">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
                <b-col cols="4" md="3">
                  <b-skeleton type="button" width="100%"></b-skeleton>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <ModalInAddition
      :prescriptions="inAdditionPrescriptions"
      :processing="processing"
      @in-addition="() => addPrescription()"
      @modal-close="hasDuplicate = false"
    />
    <ModalPrescribeEstrogenAlert
      :processing="processing"
      @prescribe="() => addPrescription(true)"
    />
  </PatientInfoLayout>
</template>

<script>
import Vue from "vue";
import {
  format,
  parse,
  add,
  sub,
  getUnixTime,
  fromUnixTime,
  differenceInCalendarMonths,
  getHours,
  getMinutes,
  getSeconds,
} from "date-fns";
import { mapGetters, mapState } from "vuex";
import popper from "@/assets/js/popper.js";
import { PrescriptionService } from "@/services/prescription.service";
import { nanoid } from "nanoid";
import PatientInfoLayout from "@/layouts/PatientInfoLayout.vue";
import ModalInAddition from "@/components/prescription/ModalInAddition.vue";
import ModalPrescribeEstrogenAlert from "@/components/prescription/ModalPrescribeEstrogenAlert.vue";
import _ from "lodash";

export default {
  name: "Order",
  components: {
    PatientInfoLayout,
    ModalInAddition,
    ModalPrescribeEstrogenAlert,
  },
  mixins: [popper],
  data() {
    return {
      processing: false,
      config: {
        altInput: true,
        altFormat: "m-d-Y",
        allowInput: true,
      },
      productid: "",
      prescriptions: [],
      orders: {
        prescription: [],
      },
      dosages: {},
      units: [],
      dosageSelected: {},
      frequency: ["Daily", "Weekly", "Monthly"],
      prescription: {
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: "",
      },
      subscriptionstartdate: format(new Date(), "yyyy-MM-dd"),
      options: [
        { value: null, text: "Select Option" },
        { value: "a", text: "This is First option" },
        { value: "b", text: "Selected Option" },
      ],
      alert: {
        show: false,
        type: "info",
        message: "",
      },
      prescriptionforconfirmation: [],
      followUpOptions: [
        { value: "3 Month", text: "3 Months" },
        { value: "1 Year", text: "1 Year" },
        { value: "Manual", text: "Manual" },
      ],
      selectedFollowUpOption: "3 Month",
      followupDate: "",

      prevRoute: "",
      activePrescriptions: [],
      inAdditionPrescriptions: [],
      duplicateActivePrescription: [],
      duplicateEntryPrescription: [],
      hasDuplicate: false,
      force: false,
      unreadAdminMessage: 0,

      patientConversationId: "",
      adminConversationId: "",
    };
  },
  watch: {
    selectedFollowUpOption: {
      handler(val) {
        if (val === "3 Month") {
          this.followupDate = format(
            add(new Date(), { days: 3 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else if (val === "1 Year") {
          this.followupDate = format(
            add(new Date(), { days: 13 * 28 - 7 }),
            "yyyy-MM-dd"
          );
        } else {
          this.followupDate = format(
            add(new Date(), { days: 1 }),
            "yyyy-MM-dd"
          );
        }
      },
      immediate: true,
    },
  },
  methods: {
    dateDisabled(ymd, date) {
      return new Date() > date;
    },
    subscriptionStartDateDisabled(ymd, date) {
      return sub(new Date(), { days: 1 }) > date;
    },
    alertMessage(type, message) {
      this.alert = {
        type: type,
        message: message,
        show: true,
      };
    },
    changePrescription(order) {
      order.pricingid = "";
      order.dosage = "";
      order.notes = "";
    },
    changeDosage(order) {
      const firstItem = this.units[order.productid][0];
      const isDHEA = firstItem.name === "DHEA";

      let unit = this.units[order.productid].find((o) =>
        isDHEA ? o.dosage === order.dosage : o.text.includes("3 months")
      );

      if (!unit && !isDHEA) {
        unit = this.units[order.productid].find(
          (o) => o.dosage === order.dosage
        );
      }

      order.pricingid = unit?.value || "";
      order.unit = unit?.text;
      order.notes = unit?.notes;

      if (unit?.defaultrefill || unit?.defaultrefill === 0) {
        order.refillinput = unit.defaultrefill;
        return;
      }

      if (unit?.name === "Blossom") {
        order.refillinput = 0;
      } else {
        if (unit?.subscriptiontype === "Monthly") {
          order.refillinput = 3;
        } else if (unit?.subscriptiontype === "Quarterly") {
          order.refillinput = 1;
        }
      }
    },
    selectedUnit(productid, pricingid) {
      if (productid) {
        let unit = this.units[productid].filter((o) => {
          return o.value === pricingid;
        });

        for (let i = 0; i < this.orders.prescription.length; i++) {
          let order = this.orders.prescription[i];
          if (productid == order.productid) {
            let selectedDosage = unit.find(
              (val) => val.dosage === order.dosage
            );
            if (selectedDosage) {
              if (pricingid === order.pricingid) {
                const foundUnit = unit.find(
                  (item) =>
                    item.value === order.pricingid &&
                    item.dosage === order.dosage
                );
                if (foundUnit) {
                  order.unit = foundUnit.text;
                }
                order.notes = selectedDosage.notes;

                if (
                  selectedDosage?.defaultrefill ||
                  selectedDosage?.defaultrefill === 0
                ) {
                  order.refillinput = selectedDosage?.defaultrefill;
                } else {
                  if (selectedDosage.subscriptiontype === "Monthly") {
                    order.refillinput = 3;
                  } else if (selectedDosage.subscriptiontype === "Quarterly") {
                    order.refillinput = 1;
                  }
                }
              }
            }
          }
        }
      }
    },
    addNewPrescription() {
      this.orders.prescription.push({
        pricingid: "",
        productid: "",
        dosage: "",
        unit: "",
        notes: "",
        refillinput: 0,
        id: nanoid(),
      });
    },
    cancelSendConfirmation() {
      this.$router.push(`/patients/profile/${this.patientInfo.id}`);
      this.processing = false;
    },
    async SendConfirmation() {
      for (let record of this.prescriptionforconfirmation) {
        await PrescriptionService.sendPrescriptionOrderConfirmation(
          record.patientid,
          record.id
        );
      }
      this.$router.push(`/patients/profile/${this.patientInfo.id}`);
      this.processing = false;
    },

    async addPrescription(force) {
      this.processing = true;
      this.orders.prescription = this.orders.prescription.filter(function (
        obj
      ) {
        return obj.productid != "";
      });

      if (!this.checkEmptyFields(this.orders.prescription)) {
        this.processing = false;
        return;
      }

      let finalPrescription = this.orders.prescription;
      if (this.hasDuplicate) {
        const filteredCurrentList = this.inAdditionPrescriptions.filter(
          (prescription) => prescription.hascurrent
        );
        const currentInAdditionList = this.orders.prescription
          .filter((prescription) =>
            filteredCurrentList.some(
              (item) => item.productid === prescription.productid
            )
          )
          .map((prescription) => ({ ...prescription, inaddition: true }));

        let newInAdditionList = this.orders.prescription
          .filter(
            (prescription) =>
              !filteredCurrentList.some(
                (item) => item.productid === prescription.productid
              )
          )
          .reverse();

        let newProductIds = _.uniq(
          newInAdditionList.map((prescription) => prescription.productid)
        );

        newInAdditionList = newInAdditionList
          .map((prescription) => {
            const index = newProductIds.findIndex(
              (productid) => productid === prescription.productid
            );
            if (index > -1) {
              newProductIds.splice(index, 1);
              return {
                ...prescription,
                inaddition: true,
              };
            } else {
              return prescription;
            }
          })
          .reverse();

        finalPrescription = [...newInAdditionList, ...currentInAdditionList];
      }

      try {
        if (!this.force) {
          this.force = force;
        }

        finalPrescription = this.processDuplicates(finalPrescription);
        await PrescriptionService.addMultiplePrescriptionOrderV2(
          this.patientId,
          {
            followupstamp: this.patientInfo.followupdate
              ? this.patientInfo.followupdatestamp
              : getUnixTime(
                  parse(
                    this.getDateStringWithCurrentTime(this.followupDate),
                    "yyyy-MM-dd H:m:s",
                    new Date()
                  )
                ),
            prescription: finalPrescription.map(({ id, ...rest }) => {
              return {
                ...rest,
                timestampsubscriptionstartdate: getUnixTime(
                  parse(
                    this.getDateStringWithCurrentTime(
                      this.subscriptionstartdate
                    ),
                    "yyyy-MM-dd H:m:s",
                    new Date()
                  )
                ),
              };
            }),
            ...(this.force && { force: true }),
          }
        );

        if (
          [
            "verified",
            "patientmessage",
            "intakehistory",
            "treatments",
          ].includes(this.prevRoute.name)
        ) {
          this.$router.push(`/patients/${this.patientInfo.id}/treatments`);
        } else {
          this.$router.push(`/patients/profile/${this.patientInfo.id}`);
        }
        this.processing = false;
        this.$bvModal.hide("inaddition");
        this.$bvModal.hide("prescribe-estrogen-alert");
        this.hasDuplicate = false;
        this.force = false;
      } catch (err) {
        this.processing = false;

        if (
          err?.message?.includes(
            "You are prescribing Estrogen to a patient that has not been prescribed Progesterone."
          )
        ) {
          this.$bvModal.show("prescribe-estrogen-alert");
          return;
        }

        if (err.message !== "Duplicate prescription") {
          this.alertMessage("warning", err.message);
        } else {
          const duplicateActivePrescription = [];
          const duplicateList = err?.response?.data?.body?.duplicate;
          duplicateList.forEach((prescription) => {
            duplicateActivePrescription.push({
              status: prescription.status,
              date: prescription.date,
              prescription: prescription.medicinename,
              dosage: prescription.dosage,
              unit: prescription.unit,
              refills: prescription.refills,
              productid: prescription.productid,
              ...(prescription.status === "New" &&
                duplicateList.some(
                  (item) =>
                    item.productid === prescription.productid &&
                    item.status === "Current"
                ) && {
                  hascurrent: true,
                }),
            });
          });
          this.inAdditionPrescriptions = duplicateActivePrescription;
          this.hasDuplicate = true;
          this.$bvModal.show("inaddition");
        }
      }
    },
    processDuplicates(array) {
      let storage = [];
      for (let i = 0; i < array.length; i++) {
        const duplicateIndex = storage.findIndex(
          (item) =>
            array[i].productid === item.productid &&
            array[i].pricingid === item.pricingid
        );
        if (duplicateIndex > -1) {
          if (!storage[duplicateIndex]?.inaddition) {
            storage[duplicateIndex].inaddition = true;
          }
          storage.push({ ...array[i], inaddition: true });
        } else {
          storage.push(array[i]);
        }
      }
      return storage;
    },
    async getPrescriptions() {
      const products = await this.$store.dispatch(
        "prescription/getProductDosageAndUnit",
        { patientid: this.patientId }
      );

      this.prescriptions = products.map(
        ({ id: productid, details: dosages, name }) => {
          this.dosages[productid] = [];
          this.units[productid] = [];
          for (let i = 0; i < dosages.length; i++) {
            let dosage = dosages[i];
            this.dosages[productid].push({
              value: dosage.dosage,
              text: dosage.dosagedisplay,
            });

            for (let j = 0; j < dosage.detail.length; j++) {
              let unit = dosage.detail[j];
              this.units[productid].push({
                value: unit.id,
                text: unit.unit,
                subscriptiontype: unit.subscriptiontype,
                notes: unit.notes,
                dosage: dosage.dosage,
                default: dosage.default ? true : false,
                name,
                defaultrefill: unit.defaultrefill,
              });
            }
          }

          return {
            value: productid,
            text: name,
          };
        }
      );
    },
    getDateStringWithCurrentTime(dateString) {
      const currentDate = new Date();
      return `${dateString} ${getHours(currentDate)}:${getMinutes(
        currentDate
      )}:${getSeconds(currentDate)}`;
    },
    cancel() {
      if (
        ["verified", "patientmessage", "intakehistory", "treatments"].includes(
          this.prevRoute.name
        )
      ) {
        this.$router.back();
      } else {
        this.$router.push(`/patients/profile/${this.patientInfo.id}`);
      }
    },
    getHealthQuestions() {
      this.$store
        .dispatch("patients/getHealthQuestions", this.patientId)
        .then((res) => {});
    },
    removePrescriptionOrder(id) {
      const removeIndex = this.orders.prescription.findIndex(
        (item) => item.id === id
      );
      this.orders.prescription.splice(removeIndex, 1);
    },
    checkEmptyFields(prescriptions) {
      let isValid = true;
      const hasNotes = prescriptions.every(
        (prescription) => prescription.notes
      );
      if (!hasNotes) {
        this.alertMessage("warning", "Doctor Notes field is required.");
        isValid = false;
      }
      return isValid;
    },
    parseDate(date, stringFormat) {
      return format(fromUnixTime(date), stringFormat);
    },
    pluralize(amount, singular, plural = `${singular}s`) {
      return amount === 1 ? singular : plural;
    },
    refillInputFormatter(value) {
      return Math.floor(Math.abs(value));
    },
    onMessageRedirect(isAdmin) {
      if (isAdmin) {
        this.$store.dispatch("message/markConversationAsRead", {
          conversationid: this.adminConversationId,
        });
        this.$router.push(`/patients/${this.patientId}/message?type=admin`);
      } else {
        this.$store.dispatch("message/markConversationAsRead", {
          conversationid: this.patientConversationId,
        });
        this.$router.push(`/patients/${this.patientId}/message`);
      }
    },
  },
  computed: {
    monthsUntilFollowUp() {
      return differenceInCalendarMonths(
        new Date(this.patientInfo.followupdate),
        new Date()
      );
    },
    ...mapGetters({
      patientInfo: "patients/getPatientInfo",
      previousRoute: "globals/previousRoute",
    }),
    ...mapState("message", {
      unreadMessageCounter: "patientUnreadMessageCount",
    }),
  },
  async mounted() {
    this.$store.dispatch("message/setPatientUnreadMessageCount", 0);
    window.analytics.page();
    this.patientId = this.$route.params.id;
    await this.$store.dispatch("patients/getPatientInfo", {
      patientId: this.patientId,
    });

    if (this.patientInfo.hasansweredfollowup) {
      this.selectedFollowUpOption = "1 Year";
    }

    await this.getPrescriptions();

    this.$store
      .dispatch("message/getSingleConversationList", {
        patientid: this.patientId,
      })
      .then(async (conversation) => {
        if (conversation.length > 0) {
          const { data } = await this.$store.dispatch(
            "message/getUnreadMessageCount",
            {
              conversationid: conversation[0].id,
            }
          );
          this.patientConversationId = conversation[0].id;
          this.$store.dispatch("message/setPatientUnreadMessageCount", data);
        }
      });

    this.$store
      .dispatch("message/getSingleAdminConversationList", {
        patientid: this.patientId,
      })
      .then(async (conversation) => {
        if (conversation) {
          const { data } = await this.$store.dispatch(
            "message/getUnreadMessageCount",
            {
              conversationid: conversation.id,
            }
          );
          this.adminConversationId = conversation.id;
          this.unreadAdminMessage = data;
        }
      });

    // Fetch all prescription orders to check if desired plan is already prescribed
    const prescriptionOrders = await this.$store.dispatch(
      "prescription/getPrescriptionOrderPerPatient",
      {
        patientid: this.patientId,
      }
    );

    const filteredPrescriptionOrders = prescriptionOrders.items.filter(
      (item) =>
        item.prescriptionstatus === "DRAFTED" ||
        item.prescriptionstatus === "PRESCRIBED" ||
        item.prescriptionstatus === "PAUSED"
    );
    this.activePrescriptions = filteredPrescriptionOrders;
    const filteredDesiredPlan = this.patientInfo.desiredplan.filter(
      (plan) =>
        !filteredPrescriptionOrders.some(
          (order) => plan.productid === order.productid
        )
    );
    const filteredUpsell = this.patientInfo.upsell.filter(
      (plan) =>
        !filteredPrescriptionOrders.some(
          (order) => plan.productid === order.productid
        )
    );

    // Added by: Mariwin
    let firstTimeUpsell = true;
    const checkUpsellPrescriptionOrders = prescriptionOrders.items.filter(
      (item) =>
        item.prescriptionstatus === "PRESCRIBED" ||
        item.prescriptionstatus === "COMPLETED" ||
        item.prescriptionstatus === "CANCELLED"
    );
    this.patientInfo.upsell.filter((plan) =>
      checkUpsellPrescriptionOrders.some((order) => {
        if (plan.productid === order.productid) {
          firstTimeUpsell = false;
        }
      })
    );

    if (this.patientInfo?.migratedprescriptions?.length > 0) {
      for (const plan of this.patientInfo?.migratedprescriptions) {
        this.orders.prescription.push({
          pricingid: plan.pricingid,
          productid: plan.productid,
          dosage: plan.dosage,
          unit: plan.unit,
          notes: plan.notes,
          refillinput: plan.refillinput,
          id: nanoid(),
        });
      }
    } else {
      if (filteredDesiredPlan.length > 0 || filteredUpsell.length > 0) {
        for (const plan of filteredDesiredPlan) {
          const currentPricing = plan.availablepricing.find(
            (price) => price.pricingid === plan.pricingdetail.pricingid
          );
          const selectedPricing = this.units[plan.productid].find(
            (unit) =>
              unit.default &&
              unit.subscriptiontype === currentPricing?.subscriptiontype
          );

          if (selectedPricing) {
            let refillinput;
            if (currentPricing?.subscriptiontype === "Monthly") {
              refillinput = 3;
            } else if (currentPricing?.subscriptiontype === "Quarterly") {
              refillinput = 1;
            } else {
              refillinput = 0;
            }

            this.orders.prescription.push({
              pricingid: selectedPricing.value,
              productid: plan.productid,
              dosage: selectedPricing.dosage,
              unit: selectedPricing.text,
              notes: selectedPricing.notes,
              refillinput:
                selectedPricing.defaultrefill ||
                selectedPricing.defaultrefill === 0
                  ? selectedPricing.defaultrefill
                  : refillinput,
              id: nanoid(),
            });
          }
        }

        for (const upsell of filteredUpsell) {
          const selectedPricing = this.units[upsell.productid].find(
            (unit) => unit.default && unit.value === upsell.pricingid
          );

          if (selectedPricing) {
            let refillinput;
            if (selectedPricing?.subscriptiontype === "Monthly") {
              refillinput = 3;
            } else if (selectedPricing?.subscriptiontype === "Quarterly") {
              refillinput = 1;
            } else {
              refillinput = 0;
            }

            this.orders.prescription.push({
              pricingid: selectedPricing.value,
              productid: upsell.productid,
              dosage: selectedPricing.dosage,
              unit: selectedPricing.text,
              notes: selectedPricing.notes,
              refillinput:
                selectedPricing.defaultrefill ||
                selectedPricing.defaultrefill === 0
                  ? selectedPricing.defaultrefill
                  : refillinput,
              id: nanoid(),
            });
          }
        }
      } else {
        // Empty prescription card
        this.orders.prescription.push({
          pricingid: "",
          productid: "",
          dosage: "",
          unit: "",
          notes: "",
          refillinput: 0,
          id: nanoid(),
        });
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      Vue.set(vm, "prevRoute", from);
    });
  },
};
</script>

<style scoped>
/deep/.input-group > .input-group-prepend > .input-group-text {
  border-radius: 0;
  border: 0;
  background-color: transparent;
}
</style>

<style lang="scss" scoped>
.prescription-card {
  background-color: $quaternary;
  border-color: $quaternary;
  padding: 1.25rem;
}

.update-order,
.followup-card,
.prescription-details {
  background-color: rgba(26, 106, 114, 0.03);
}
</style>
