var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PatientInfoLayout",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "div",
            {
              staticClass:
                "tw-top-0 tw-py-5 lg:tw-flex tw-justify-between tw-items-center tw-flex-none tw-px-4",
              attrs: { c: "" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "tw-flex-initial tw-flex tw-items-center tw-text-warning",
                },
                [
                  _c("span", {
                    staticClass: "back-arrow tw-mr-3 tw-cursor-pointer",
                    on: {
                      click: function ($event) {
                        return _vm.$router.back()
                      },
                    },
                  }),
                  _vm.previousRoute
                    ? _c("span", { staticClass: "tw-text-warning" }, [
                        _vm._v(
                          " " + _vm._s(_vm.previousRoute.meta.title) + " "
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                { staticClass: "lg:tw-pr-6" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "tw-text-primary tw-underline",
                      attrs: { size: "sm", variant: "link", pill: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$router.push(
                            `/patients/${_vm.patientId}/intake`
                          )
                        },
                      },
                    },
                    [_vm._v(" View Intake History ")]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "b-row",
            {
              staticClass: "tw-px-4",
              staticStyle: { "padding-top": "20px", "padding-bottom": "20px" },
              attrs: { "align-h": "center", "align-v": "center" },
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-row",
                    [
                      _vm.patientInfo && _vm.patientInfo.firstname
                        ? _c("b-col", { attrs: { cols: "12" } }, [
                            _vm.unreadMessageCounter > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-bg-[#FF8D40] tw-text-[#FFECCA] tw-p-1 tw-my-4 tw-text-center tw-cursor-pointer",
                                    on: {
                                      click: () => _vm.onMessageRedirect(false),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " You have " +
                                        _vm._s(_vm.unreadMessageCounter) +
                                        " " +
                                        _vm._s(
                                          _vm.pluralize(
                                            _vm.unreadMessageCounter,
                                            "message"
                                          )
                                        ) +
                                        " from " +
                                        _vm._s(
                                          `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}`
                                        ) +
                                        " "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.unreadAdminMessage > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "tw-bg-[#FF8D40] tw-text-[#FFECCA] tw-p-1 tw-my-4 tw-text-center tw-cursor-pointer",
                                    on: {
                                      click: () => _vm.onMessageRedirect(true),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " You have " +
                                        _vm._s(_vm.unreadAdminMessage) +
                                        " " +
                                        _vm._s(
                                          _vm.pluralize(
                                            _vm.unreadAdminMessage,
                                            "message"
                                          )
                                        ) +
                                        " from Admin "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("h4", { staticClass: "mr-4 text-primary" }, [
                            _vm._v("Patient Information"),
                          ]),
                          !_vm.patientInfo.patientno
                            ? _c(
                                "b-card",
                                {
                                  staticClass: "prescription-card",
                                  attrs: { "no-body": "" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { attrs: { "align-v": "center" } },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "9" } },
                                        [
                                          _c(
                                            "b-row",
                                            { attrs: { "align-v": "center" } },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass: "mb-3",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c("b-skeleton", {
                                                    attrs: {
                                                      animation: "fade",
                                                      type: "avatar",
                                                      size: "72px",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                [
                                                  _c("b-skeleton", {
                                                    attrs: {
                                                      animation: "fade",
                                                      width: "45%",
                                                    },
                                                  }),
                                                  _c("b-skeleton", {
                                                    attrs: {
                                                      animation: "fade",
                                                      width: "55%",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "text-right",
                                          attrs: { cols: "12", md: "3" },
                                        },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              width: "85%",
                                            },
                                          }),
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              width: "65%",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _c(
                                "b-card",
                                {
                                  staticClass: "prescription-card",
                                  attrs: { "no-body": "" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c(
                                            "b-row",
                                            { attrs: { "align-v": "center" } },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "9",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    {
                                                      attrs: {
                                                        "align-v": "center",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass: "mb-3",
                                                          attrs: {
                                                            cols: "4",
                                                            lg: "2",
                                                          },
                                                        },
                                                        [
                                                          _c("b-avatar", {
                                                            attrs: {
                                                              size: "72px",
                                                              src: _vm
                                                                .patientInfo
                                                                .profileUrl,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: {
                                                            cols: "8",
                                                            lg: "10",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "h3",
                                                            {
                                                              staticClass:
                                                                "mb-1 bold",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    `${_vm.patientInfo.firstname} ${_vm.patientInfo.lastname}`
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _vm.patientInfo
                                                                .transferredfromdoctorname
                                                                ? _c("small", [
                                                                    _vm._v(
                                                                      " (Transferred from Dr. " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .patientInfo
                                                                            .transferredfromdoctorname
                                                                        ) +
                                                                        ") "
                                                                    ),
                                                                  ])
                                                                : _vm._e(),
                                                            ]
                                                          ),
                                                          _vm.patientInfo
                                                            .desiredplan
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Requested Medication: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      _vm._l(
                                                                        _vm
                                                                          .patientInfo
                                                                          .desiredplan,
                                                                        function (
                                                                          plan,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "span",
                                                                            {
                                                                              key: i,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    plan.name +
                                                                                      (plan.pricingdetail !=
                                                                                      undefined
                                                                                        ? " - " +
                                                                                          plan
                                                                                            .pricingdetail
                                                                                            .nickname
                                                                                        : (i ===
                                                                                          _vm
                                                                                            .patientInfo
                                                                                            .desiredplan
                                                                                            .length -
                                                                                            1
                                                                                            ? ""
                                                                                            : ",") ||
                                                                                          "")
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                              plan.pricingdetail !=
                                                                              undefined
                                                                                ? _c(
                                                                                    "br"
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm.patientInfo
                                                                .preferredtreatment
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Requested Medication: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " " +
                                                                            _vm._s(
                                                                              _vm
                                                                                .patientInfo
                                                                                .preferredtreatment ||
                                                                                ""
                                                                            ) +
                                                                            " "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Requested Medication: "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " Let doctor choose medication "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                          _vm.patientInfo.upsell
                                                            .length > 0
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Requested Upsell:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      _vm._l(
                                                                        _vm
                                                                          .patientInfo
                                                                          .upsell,
                                                                        function (
                                                                          upsell,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "div",
                                                                            {
                                                                              key: i,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    upsell.name
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                              upsell.subscriptiontype
                                                                                ? _c(
                                                                                    "span",
                                                                                    [
                                                                                      _vm._v(
                                                                                        " (" +
                                                                                          _vm._s(
                                                                                            upsell.subscriptiontype
                                                                                          ) +
                                                                                          ") "
                                                                                      ),
                                                                                    ]
                                                                                  )
                                                                                : _vm._e(),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm.patientInfo
                                                            .preferredshipmentname
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Preferred Shipment:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          [
                                                                            _vm._v(
                                                                              " " +
                                                                                _vm._s(
                                                                                  _vm
                                                                                    .patientInfo
                                                                                    .preferredshipmentname ||
                                                                                    ""
                                                                                ) +
                                                                                " "
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                          _vm.patientInfo
                                                            .unselectedproduct !=
                                                            undefined &&
                                                          _vm.patientInfo
                                                            .unselectedproduct
                                                            .length > 0
                                                            ? [
                                                                _c(
                                                                  "b-row",
                                                                  {
                                                                    staticClass:
                                                                      "py-1",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        attrs: {
                                                                          cols: "12",
                                                                          lg: "4",
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Declined onboarding treatment:"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c(
                                                                      "b-col",
                                                                      {
                                                                        staticClass:
                                                                          "text-black",
                                                                      },
                                                                      _vm._l(
                                                                        _vm
                                                                          .patientInfo
                                                                          .unselectedproduct,
                                                                        function (
                                                                          plan,
                                                                          i
                                                                        ) {
                                                                          return _c(
                                                                            "span",
                                                                            {
                                                                              key: i,
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    plan.name +
                                                                                      (i ===
                                                                                      _vm
                                                                                        .patientInfo
                                                                                        .unselectedproduct
                                                                                        .length -
                                                                                        1
                                                                                        ? ""
                                                                                        : ",") ||
                                                                                      ""
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          )
                                                                        }
                                                                      ),
                                                                      0
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            : _vm._e(),
                                                        ],
                                                        2
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "text-right tw-mt-10 md:tw-mt-0",
                                                  attrs: {
                                                    cols: "12",
                                                    lg: "3",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "h3",
                                                    {
                                                      staticClass: "mb-0 bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.patientInfo
                                                              .patientno
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "a",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-modal",
                                                          rawName:
                                                            "v-b-modal.intake",
                                                          modifiers: {
                                                            intake: true,
                                                          },
                                                        },
                                                      ],
                                                      staticClass:
                                                        "text-underline",
                                                      attrs: { href: "#" },
                                                      on: {
                                                        click:
                                                          _vm.getHealthQuestions,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Intake History "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.prescriptions.length > 0
                    ? _c(
                        "b-row",
                        { attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "mr-4 text-primary" }, [
                                _vm._v("Follow-up"),
                              ]),
                              _c(
                                "b-card",
                                {
                                  staticClass: "followup-card",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _vm.patientInfo.followupdate
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "tw-mb-4 lg:tw-mb-0",
                                              attrs: { cols: "12", md: "3" },
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mb-1 text-primary",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v(
                                                      "Current Follow up Date"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 text-default",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.parseDate(
                                                          _vm.patientInfo
                                                            .followupdatestamp,
                                                          "MMMM d, yyyy"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "3" } },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "mb-1 text-primary",
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v(
                                                      "Months Until Follow Up"
                                                    ),
                                                  ]),
                                                ]
                                              ),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "mb-0 text-default",
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.monthsUntilFollowUp
                                                      ) +
                                                      " " +
                                                      _vm._s(
                                                        _vm.pluralize(
                                                          _vm.monthsUntilFollowUp,
                                                          "Month"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-row",
                                        { staticClass: "mb-4" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "6" } },
                                            [
                                              _c(
                                                "div",
                                                { attrs: { role: "group" } },
                                                [
                                                  _c(
                                                    "label",
                                                    {
                                                      staticClass:
                                                        "text-primary",
                                                      attrs: {
                                                        for: "followup",
                                                      },
                                                    },
                                                    [
                                                      _c("small", [
                                                        _vm._v(
                                                          "Schedule Follow-up"
                                                        ),
                                                      ]),
                                                    ]
                                                  ),
                                                  _c("v-select", {
                                                    attrs: {
                                                      id: "followup",
                                                      "append-to-body": "",
                                                      "calculate-position":
                                                        _vm.withPopper,
                                                      label: "text",
                                                      options:
                                                        _vm.followUpOptions,
                                                      reduce: (
                                                        followUpOptions
                                                      ) =>
                                                        followUpOptions.value,
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "open-indicator",
                                                          fn: function ({
                                                            attributes,
                                                          }) {
                                                            return [
                                                              _c(
                                                                "b-icon",
                                                                _vm._b(
                                                                  {
                                                                    attrs: {
                                                                      icon: "chevron-down",
                                                                      variant:
                                                                        "primary",
                                                                    },
                                                                  },
                                                                  "b-icon",
                                                                  attributes,
                                                                  false
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      709101353
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.selectedFollowUpOption,
                                                      callback: function ($$v) {
                                                        _vm.selectedFollowUpOption =
                                                          $$v
                                                      },
                                                      expression:
                                                        "selectedFollowUpOption",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "tw-mt-6 md:tw-mt-0",
                                              attrs: { cols: "12", md: "6" },
                                            },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "text-primary",
                                                  attrs: {
                                                    for: "followupdate",
                                                  },
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v("Follow-up Date"),
                                                  ]),
                                                ]
                                              ),
                                              _c("b-form-datepicker", {
                                                attrs: {
                                                  id: "followupdate",
                                                  disabled:
                                                    _vm.selectedFollowUpOption !==
                                                    "Manual",
                                                  "date-disabled-fn":
                                                    _vm.dateDisabled,
                                                },
                                                model: {
                                                  value: _vm.followupDate,
                                                  callback: function ($$v) {
                                                    _vm.followupDate = $$v
                                                  },
                                                  expression: "followupDate",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.prescriptions.length > 0
                    ? _c(
                        "b-row",
                        { staticClass: "pb-6", attrs: { "align-h": "center" } },
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("h4", { staticClass: "mr-4 text-primary" }, [
                                _vm._v("Prescription Order"),
                              ]),
                              _c(
                                "b-alert",
                                {
                                  attrs: {
                                    show: _vm.alert.show,
                                    variant: _vm.alert.type,
                                  },
                                },
                                [_vm._v(_vm._s(_vm.alert.message))]
                              ),
                              _c(
                                "b-card",
                                {
                                  staticClass: "prescription-details",
                                  staticStyle: { position: "relative" },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "text-primary",
                                              attrs: {
                                                for: "subscription_startdate",
                                              },
                                            },
                                            [
                                              _c("small", [
                                                _vm._v(
                                                  "Subscription Start Date"
                                                ),
                                              ]),
                                            ]
                                          ),
                                          _c("b-form-datepicker", {
                                            attrs: {
                                              id: "subscription_startdate",
                                              "date-disabled-fn":
                                                _vm.subscriptionStartDateDisabled,
                                            },
                                            model: {
                                              value: _vm.subscriptionstartdate,
                                              callback: function ($$v) {
                                                _vm.subscriptionstartdate = $$v
                                              },
                                              expression:
                                                "subscriptionstartdate",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._l(_vm.orders.prescription, function (order) {
                                return _c(
                                  "b-card",
                                  {
                                    key: order.id,
                                    staticClass: "update-order",
                                    staticStyle: { position: "relative" },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "10px",
                                          right: "10px",
                                          padding: "2px 0px",
                                          cursor: "pointer",
                                          "z-index": "5",
                                        },
                                        attrs: { size: "sm", variant: "link" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removePrescriptionOrder(
                                              order.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "svg",
                                          {
                                            attrs: {
                                              width: "24",
                                              height: "24",
                                              viewBox: "0 0 24 24",
                                              fill: "none",
                                              xmlns:
                                                "http://www.w3.org/2000/svg",
                                            },
                                          },
                                          [
                                            _c("circle", {
                                              attrs: {
                                                cx: "12",
                                                cy: "12",
                                                r: "11.5",
                                                stroke: "#1A6A72",
                                              },
                                            }),
                                            _c("path", {
                                              attrs: {
                                                d: "M14.903 14.189L12.7638 12.0499L14.903 9.91077L14.1899 9.19773L12.0508 11.3369L9.91162 9.19773L9.19857 9.91077L11.3377 12.0499L9.19857 14.189L9.91162 14.9021L12.0508 12.763L14.1899 14.9021L14.903 14.189Z",
                                                fill: "#1A6A72",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tw-grid tw-grid-cols-10 tw-mb-4 tw-space-y-4 lg:tw-space-y-0 md:tw-space-x-4 tw-place-items-stretch",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-4",
                                            attrs: { role: "group" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "prescription" },
                                              },
                                              [
                                                _c("small", [
                                                  _vm._v("Prescription"),
                                                ]),
                                              ]
                                            ),
                                            _c("v-select", {
                                              staticClass:
                                                "prescription-select",
                                              attrs: {
                                                id: "prescription",
                                                "append-to-body": "",
                                                "calculate-position":
                                                  _vm.withPopper,
                                                label: "text",
                                                options: _vm.prescriptions,
                                                disabled:
                                                  _vm.prescriptions.length <= 0,
                                                reduce: (prescriptions) =>
                                                  prescriptions.value,
                                                searchable: false,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changePrescription(
                                                    order
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "open-indicator",
                                                    fn: function ({
                                                      attributes,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-icon",
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: "chevron-down",
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            "b-icon",
                                                            attributes,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: order.productid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    order,
                                                    "productid",
                                                    $$v
                                                  )
                                                },
                                                expression: "order.productid",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-2",
                                            attrs: { role: "group" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "dosage" },
                                              },
                                              [_c("small", [_vm._v("Dosage")])]
                                            ),
                                            _c("v-select", {
                                              staticClass:
                                                "prescription-select",
                                              attrs: {
                                                id: "dosage",
                                                "append-to-body": "",
                                                "calculate-position":
                                                  _vm.withPopper,
                                                label: "text",
                                                options:
                                                  _vm.dosages[
                                                    order.productid
                                                  ] &&
                                                  _vm.dosages[
                                                    order.productid
                                                  ].filter(
                                                    ({ value }) => value !== ""
                                                  ),
                                                reduce: (dosages) =>
                                                  dosages.value,
                                                disabled: !order.productid,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.changeDosage(order)
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "open-indicator",
                                                    fn: function ({
                                                      attributes,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-icon",
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: "chevron-down",
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            "b-icon",
                                                            attributes,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: order.dosage,
                                                callback: function ($$v) {
                                                  _vm.$set(order, "dosage", $$v)
                                                },
                                                expression: "order.dosage",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-3",
                                            attrs: { role: "group" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "unit" },
                                              },
                                              [_c("small", [_vm._v("Unit")])]
                                            ),
                                            _c("v-select", {
                                              staticClass:
                                                "prescription-select",
                                              attrs: {
                                                id: "unit",
                                                "append-to-body": "",
                                                "calculate-position":
                                                  _vm.withPopper,
                                                label: "text",
                                                options:
                                                  _vm.units[order.productid] &&
                                                  _vm.units[
                                                    order.productid
                                                  ].filter(
                                                    (unit) =>
                                                      unit.dosage ===
                                                      order.dosage
                                                  ),
                                                reduce: (units) => units.value,
                                                clearable: "",
                                                disabled: !order.dosage,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.selectedUnit(
                                                    order.productid,
                                                    order.pricingid
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "open-indicator",
                                                    fn: function ({
                                                      attributes,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "b-icon",
                                                          _vm._b(
                                                            {
                                                              attrs: {
                                                                icon: "chevron-down",
                                                                variant:
                                                                  "primary",
                                                              },
                                                            },
                                                            "b-icon",
                                                            attributes,
                                                            false
                                                          )
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                              model: {
                                                value: order.pricingid,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    order,
                                                    "pricingid",
                                                    $$v
                                                  )
                                                },
                                                expression: "order.pricingid",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tw-justify-between tw-flex tw-flex-col tw-col-span-10 md:tw-col-span-1",
                                            attrs: { role: "group" },
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "text-primary",
                                                attrs: { for: "unit" },
                                              },
                                              [_c("small", [_vm._v("Refill")])]
                                            ),
                                            _c("b-form-input", {
                                              attrs: {
                                                id: "refill",
                                                type: "number",
                                                min: "0",
                                                formatter:
                                                  _vm.refillInputFormatter,
                                              },
                                              model: {
                                                value: order.refillinput,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    order,
                                                    "refillinput",
                                                    _vm._n($$v)
                                                  )
                                                },
                                                expression: "order.refillinput",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "b-row",
                                      [
                                        _c("b-col", { attrs: { cols: "12" } }, [
                                          _c(
                                            "div",
                                            { attrs: { role: "group" } },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "text-primary",
                                                  attrs: { for: "textarea" },
                                                },
                                                [
                                                  _c("small", [
                                                    _vm._v("Doctor Notes"),
                                                  ]),
                                                ]
                                              ),
                                              _c("b-form-textarea", {
                                                staticClass: "pl-0",
                                                staticStyle: {
                                                  overflow: "auto",
                                                },
                                                attrs: {
                                                  id: "textarea",
                                                  rows: "3",
                                                  "max-rows": "6",
                                                },
                                                model: {
                                                  value: order.notes,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      order,
                                                      "notes",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "order.notes",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }),
                              _c(
                                "b-row",
                                { staticClass: "tw-space-y-4 lg:tw-space-y-0" },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "btn btn-default",
                                          on: {
                                            click: function ($event) {
                                              return _vm.addNewPrescription()
                                            },
                                          },
                                        },
                                        [_vm._v(" + add new order ")]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "outline-primary",
                                            block: "",
                                          },
                                          on: { click: _vm.cancel },
                                        },
                                        [_vm._v("Cancel")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "3" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            pill: "",
                                            variant: "primary",
                                            block: "",
                                            disabled: _vm.processing,
                                          },
                                          on: {
                                            click: () => _vm.addPrescription(),
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.processing
                                                ? "Processing"
                                                : "Save"
                                            )
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            2
                          ),
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "b-card",
                                {
                                  staticStyle: {
                                    "background-color":
                                      "rgba(247, 247, 247, 0.5)",
                                  },
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "6" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "3" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mb-4" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12" } },
                                        [
                                          _c("b-skeleton", {
                                            attrs: {
                                              animation: "fade",
                                              type: "input",
                                              height: "150px",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "4", md: "6" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          type: "button",
                                          width: "150px",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "4", md: "3" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          type: "button",
                                          width: "100%",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "4", md: "3" } },
                                    [
                                      _c("b-skeleton", {
                                        attrs: {
                                          type: "button",
                                          width: "100%",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ModalInAddition", {
        attrs: {
          prescriptions: _vm.inAdditionPrescriptions,
          processing: _vm.processing,
        },
        on: {
          "in-addition": () => _vm.addPrescription(),
          "modal-close": function ($event) {
            _vm.hasDuplicate = false
          },
        },
      }),
      _c("ModalPrescribeEstrogenAlert", {
        attrs: { processing: _vm.processing },
        on: { prescribe: () => _vm.addPrescription(true) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }